<template>
  <cks-combined-route @active="refreshActive">
    <div class="reception-news-center">
      <div class="bgc">
        <img src="@/assets/images/news-image/page01.png" alt="">
        <div class="w title-item">
          <div class="title-box tx1200" ref="transform1">
            <div class="title1">News.</div>
            <div class="title2">行业资讯</div>
          </div>
        </div>
      </div>

      <div class="tab">
        <div class="item-box w">
          <div @click="navClick(0)" :class="{'active': activeIndex === 0}" class="item curr">全部</div>
          <div @click="navClick(1)" :class="{'active': activeIndex === 1}" class="item curr">企业动态</div>
          <div @click="navClick(2)" :class="{'active': activeIndex === 2}" class="item curr">行业资讯</div>
        </div>
      </div>

      <!-- 数据列表 -->
      <list :list="list"/>
      <div class="pagination-box">
        <cks-pagination v-model="pagination" :total="total"></cks-pagination>
      </div>
    </div>
  </cks-combined-route>
</template>

<script>
import {
  onMounted,
  onUnmounted, reactive, toRefs, watchEffect,
} from 'vue';
import list from './components/list.vue';
import apis from '@/request/apis';
import { itemGsap } from '../index/js/usePage';

export default {
  components: {
    list,
  },
  setup() {
    const data = reactive({
      pagination: {
        current: 1,
        size: 9,
      },
      total: 0,
      conditions: [],
      list: [],
      activeIndex: 0,
      transform1: null,
    });

    const load = async () => {
      const res = await apis.receptionNewsCenter.getPage({ ...data.pagination, conditions: data.conditions });
      data.list = res.records;
      data.total = res.total;
    };
    load();

    const navClick = (index) => {
      data.activeIndex = index;
      if (index !== 0) {
        data.conditions = JSON.stringify([{ name: 'newsType', opr: 'eq', value: index - 1 }]);
      } else {
        data.conditions = [];
      }
      data.pagination = {
        current: 1,
        size: 9,
      };
    };

    watchEffect(() => {
      if (data.pagination) {
        load();
      }
    });
    onMounted(async () => {
      itemGsap(data.transform1, {
        x: 0,
        opacity: 1,
        duration: 1,
        ease: 'none',
      });
    });
    onUnmounted(() => {
      const app = document.querySelector('#app');
      app.scrollTop = 0;
    });

    return {
      ...toRefs(data),
      navClick,
      load,
    };
  },
};
</script>

<style lang="scss" scoped>
.reception-news-center {
  .bgc {
    width: 100vw;
    height: 26.042vw;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .title-item {
    position: relative;
  }
  .title-box {
    position: absolute;
    bottom: 5.677vw;
    .title1 {
      height: 3.75vw;
      font-size: 3.385vw;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: rgba(255,255,255,0.2);
      line-height: 3.75vw;
    }
    .title2 {
      height: 1.875vw;
      font-size: 1.875vw;
      font-family: Source Han Sans-Regular, Source Han Sans;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 1.875vw;
      margin-top: -0.521vw;
    }
  }

  .tab {
    width: 100vw;
    height: 4.688vw;
    background: var(--baseColor1);
    .item-box {
      display: flex;
      height: 100%;
      .item {
        line-height: 4.688vw;
        text-align: center;
        width: 10.781vw;
        font-size: 1.823vw;
        font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
        font-weight: 400;
        color: #FFFFFF;
        &.active,
        &:hover {
          color: var(--baseColor1);
          background-color: #fff;
        }
      }
    }
  }
  .pagination-box {
    display: flex;
    justify-content: center;
    margin: 2.604vw 0vw 5.313vw;
  }

}
@media screen and (max-width: 768px) {
  .reception-news-center {
    .bgc {
      width: 100vw;
      height: 50vw;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .title-item {
      position: relative;
    }
    .title-box {
      position: absolute;
      bottom: 14.533vw;
      left: 4vw;
      .title1 {
        height: 9.6vw;
        font-size: 3.385vw;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: rgba(255,255,255,0.2);
        line-height: 3.75vw;
      }
      .title2 {
        height: 1.875vw;
        font-size: 1.875vw;
        font-family: Source Han Sans-Regular, Source Han Sans;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 1.875vw;
        margin-top: -5.333vw;
      }
    }

    .tab {
      width: 100vw;
      height: 6.688vw;
      background: var(--baseColor1);
      .item-box {
        display: flex;
        height: 100%;
        .item {
          line-height: 6.688vw;
          text-align: center;
          width: 18.781vw;
          font-size: 1.823vw;
          font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
          font-weight: 400;
          color: #FFFFFF;
          &.active,
          &:hover {
            color: var(--baseColor1);
            background-color: #fff;
          }
        }
      }
    }
    .pagination-box {
      display: flex;
      justify-content: center;
      margin: 2.604vw 0vw 5.313vw;
    }

  }
}
</style>
