<template>
  <div class="item curr" @click="detail(item.id)">
    <div class="left tx-600" ref="transformRef4">
      <img :src="item.titlePic" alt="">
    </div>
    <div class="right">
      <div class="ty600 title" ref="transformRef1">{{ item.title }}</div>
      <div class="ty600 text" ref="transformRef2">{{item.content}}</div>
      <div class="ty600 date" ref="transformRef3">{{ getTime(item.lastPubDate) }}</div>
    </div>
  </div>
</template>
<script>
import { getTime } from '@/utils';
import { onMounted, reactive, toRefs } from 'vue';
import { itemGsap } from '@/views/index/js/usePage';
import { useRouter } from 'vue-router';

export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const router = useRouter();
    const data = reactive({
      transformRef1: null,
      transformRef2: null,
      transformRef3: null,
      transformRef4: null,
    });
    onMounted(() => {
      itemGsap(data.transformRef1, {
        x: 0,
        y: 0,
        duration: 0.6,
        opacity: 1,
      });
      itemGsap(data.transformRef2, {
        x: 0,
        y: 0,
        duration: 0.6,
        opacity: 1,
      });
      itemGsap(data.transformRef3, {
        x: 0,
        y: 0,
        duration: 0.6,
        opacity: 1,
      });
      itemGsap(data.transformRef4, {
        x: 0,
        y: 0,
        duration: 0.6,
        opacity: 1,
      });
    });
    const detail = (id) => {
      router.push({
        name: 'receptionNewsCenterDetail',
        params: {
          id,
        },
      });
    };
    return {
      getTime,
      ...toRefs(data),
      detail,
    };
  },
};
</script>

<style lang="scss" scoped>
.item {
  width: 100%;
  height: 19.063vw;
  padding: 2.083vw 1.875vw;
  display: flex;
  transition: all 0.5s;
  border-bottom: 0.521vw solid transparent;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &::after {
    content: '';
    position: absolute;
    width: calc(100% - 4.166vw);
    bottom: 1px;
    left: 50%;
    transform: translateX(-50%);
    height: 1px;
    background-color: #ddd;
  }
  &:hover::after {
    opacity: 0;
  }
  &:hover {
    background: #FFFFFF;
    border-bottom: 0.521vw solid var(--baseColor1);
    box-shadow: 0px 0px 1.563vw 0px rgba(0,0,0,0.1);
  }
  .left {
    width: 22.135vw;
    height: 100%;
    flex-shrink: 0;
    background-color: #e9e9e9;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 0.5s;
    }
  }
  &:hover {
    img {
      transform: scale(1.1);
    }
  }
  .right {
    margin-left: 3.438vw;
    flex: 1;
    .title {
      height: 2.813vw;
      font-size: 1.667vw;
      font-family: Microsoft Tai Le-Bold, Microsoft Tai Le;
      font-weight: bold;
      color: #064A6C;
      line-height: 2.396vw;
    }
    .text {
      font-size: 1.042vw;
      font-family: Microsoft Sans Serif-Regular, Microsoft Sans Serif;
      font-weight: 400;
      color: #333333;
      line-height: 1.667vw;
      margin-bottom: 0.417vw;
      margin-top: 0.313vw;
    }
    .date {
      height: 2.24vw;
      font-size: 0.729vw;
      font-family: Microsoft Sans Serif-Regular, Microsoft Sans Serif;
      font-weight: 400;
      color: #BBBBBB;
      line-height: 1.875vw;
    }
  }
}
@media screen and (max-width: 768px) {
  .item {
    width: auto;
    height: 60vw;
    padding: 0;
    margin: 0 4vw;
    display: flex;
    flex-direction: column;
    transition: all 0.5s;
    border-bottom: 0.521vw solid transparent;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 0.8vw;
    background-color: #ffffff;
    margin-bottom: 3.467vw;
    &::after {
      content: '';
      position: absolute;
      width: calc(100% - 4.166vw);
      bottom: 1px;
      left: 50%;
      transform: translateX(-50%);
      height: 1px;
      background-color: #ddd;
      display: none;
    }
    &:hover {
      background: #FFFFFF;
      border-bottom: 0.521vw solid var(--baseColor1);
      box-shadow: 0px 0px 1.563vw 0px rgba(0,0,0,0.1);
    }
    .left {
      width: 100%;
      height: 70%;
      flex-shrink: 0;
      background-color: #e9e9e9;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .right {
      margin-left: 0;
      flex: 1;
      padding-left: 4vw;
      padding-right: 4vw;
      .title {
        height: 3.733vw;
        font-size: 3.733vw;
        font-family: Microsoft Tai Le-Bold, Microsoft Tai Le;
        font-weight: bold;
        color: #064A6C;
        line-height: 3.733vw;
        margin-top: 1.333vw;
        margin-bottom: 1.333vw;
        overflow: hidden;
      }
      .text {
        font-size: 2.133vw;
        font-family: Microsoft Sans Serif-Regular, Microsoft Sans Serif;
        font-weight: 400;
        color: #333333;
        line-height: 3.133vw;
        margin-bottom: 1.333vw;
        margin-top: 0;
        overflow: hidden;
      }
      .date {
        height: 2.133vw;
        font-size: 1.6vw;
        font-family: Microsoft Sans Serif-Regular, Microsoft Sans Serif;
        font-weight: 400;
        color: #cfcfcf;
        line-height: 2.133vw;
        margin-top: 1.333vw;
      }
    }
  }
}
</style>
