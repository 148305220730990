<template>
  <div class="w content">
    <item v-for="item in list" :key="random() + item.id" :item="item"/>
  </div>

</template>

<script>
import item from './item.vue';

export default {
  components: {
    item,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const random = () => parseInt(Math.random() * 0xffffff, 10).toString(16) + parseInt(Math.random() * 0xffffff, 10).toString(16);
    return {
      random,
    };
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 3.646vw 0;
}
</style>
